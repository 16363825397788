<template>
  <div>
    <header-formulir
      v-bind:date="$date()"
      v-bind:title="'Formulir Limbah Fasyankes'"
    />
    <div class="content-layout-formulir">
      <v-container>
        <!-- Tab2 -->
        <div v-if="isiFormulir && tab == 2" class="">
          <div class="side_toolbar">
            <div>
              <div class="item_toolbar" @click="setupForm(true)">
                <v-progress-circular
                  v-if="loadTempo"
                  indeterminate
                  color="#00b4cc"
                ></v-progress-circular>
                <v-icon color="#00b4cc" v-else large
                  >mdi-content-save-outline</v-icon
                >
              </div>
            </div>
          </div>
          <div class="d-flex justify-center">
            <div style="position: relative">
              <div class="d-flex">
                <v-tabs
                  v-model="tabs"
                  background-color="transparent"
                  class="d-flex fasyankes w-100 m-0"
                  hide-slider
                >
                  <v-tab
                    href="#tabs1"
                    cols="3"
                    disabled
                    class="text-capitalize mr-3 header_tab"
                    active-class="active_tab"
                    ><h3 class="title-tabs">Data Limbah Padat</h3></v-tab
                  >
                  <v-tab
                    cols="3"
                    disabled
                    href="#tabs2"
                    class="text-capitalize mr-3 header_tab"
                    active-class="active_tab"
                  >
                    <h3 class="title-tabs">Sumber daya dan Organisasi</h3>
                  </v-tab>
                  <v-tab
                    disabled
                    cols="3"
                    href="#tabs3"
                    class="text-capitalize mr-3 header_tab"
                    active-class="active_tab"
                    ><h3 class="title-tabs">
                      Alur Pengelolaan limbah padat
                    </h3></v-tab
                  >
                  <v-tab
                    cols="3"
                    disabled
                    href="#tabs4"
                    class="text-capitalize header_tab"
                    active-class="active_tab"
                    ><h3 class="title-tabs">Pengolahan Limbah Cair</h3></v-tab
                  >
                </v-tabs>
              </div>
              <v-tabs-items v-model="tabs" style="background: white" class="">
                <!-- Tab 1 -->
                <v-tab-item value="tabs1">
                  <v-card class="mh-300 custom_card pa-5" v-if="limbah_padat">
                    <!-- Soal 1 -->
                    <div class="border-soal d-flex">
                      <div class="soal-form" style="width: 90%">
                        <div class="questions">
                          <p>Apakah Dilakukan Pemilahan ?</p>
                        </div>
                        <div class="d-flex align-center">
                          <v-radio-group
                            row
                            dense
                            hide-details
                            class="ma-0"
                            v-model="
                              limbah_padat.limbah_padat[
                                'apakah_dilakukan_pemilahan_limbah_padat?'
                              ].dataPenyusun_desc
                            "
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </div>
                      </div>
                      <v-btn depressed color="#59B5CC" dark> Sesuai</v-btn>
                    </div>
                    <!-- 28577A -->
                    <!-- IF Soal 1 = Yes -->
                    <div
                      v-if="
                        limbah_padat.limbah_padat[
                          'apakah_dilakukan_pemilahan_limbah_padat?'
                        ].dataPenyusun_desc == 1
                      "
                    >
                      <!-- Soal 2 -->
                      <div class="border-soal">
                        <div class="title-soal-form">
                          <h6>Limbah Domestik</h6>
                        </div>
                        <div class="d-flex">
                          <div class="soal-form bors-sol">
                            <div class="questions">
                              <p>Apakah Dilakukan Pemilahan ?</p>
                            </div>
                            <div class="d-flex align-center">
                              <v-radio-group
                                row
                                dense
                                hide-details
                                class="ma-0"
                                v-model="
                                  limbah_padat.limbah_domestik[
                                    'apakah_dilakukan_pemilahan_domestik'
                                  ].dataPenyusun_desc
                                "
                              >
                                <v-radio
                                  color="#00ccb6"
                                  label="Ya"
                                  value="1"
                                ></v-radio>
                                <v-radio
                                  color="#00ccb6"
                                  label="Tidak"
                                  value="2"
                                ></v-radio>
                              </v-radio-group>
                            </div>
                          </div>
                          <v-btn depressed color="#59B5CC" dark> Sesuai</v-btn>
                        </div>

                        <div
                          class="added-form"
                          v-if="
                            limbah_padat.limbah_domestik[
                              'apakah_dilakukan_pemilahan_domestik'
                            ].dataPenyusun_desc == 1
                          "
                        >
                          <div class="d-flex align-center per-form-added">
                            <label class="label-tipe-1" for="limbah-organik"
                              >Limbah Organik</label
                            >
                            <div class="input-group">
                              <input
                                type="number"
                                v-model="
                                  limbah_padat.limbah_domestik['limbah_organik']
                                    .dataPenyusun_desc
                                "
                                name="limbah-organik"
                                id="limbah-organik"
                                class="form-control form-medium"
                              />
                              <div class="icon-group">kg/hari</div>
                            </div>
                          </div>
                          <div class="d-flex align-center per-form-added">
                            <label class="label-tipe-1" for="limbah-organik"
                              >Limbah Anorganik</label
                            >
                            <div class="input-group">
                              <input
                                type="number"
                                v-model="
                                  limbah_padat.limbah_domestik[
                                    'limbah_non_organik'
                                  ].dataPenyusun_desc
                                "
                                name="limbahAnorganik"
                                id="limbahAnorganik"
                                class="form-control form-medium"
                              />
                              <div class="icon-group">kg/hari</div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            limbah_padat.limbah_domestik[
                              'apakah_dilakukan_pemilahan_domestik'
                            ].dataPenyusun_desc == 2
                          "
                        >
                          <div class="added-form">
                            <div class="d-flex align-center per-form-added">
                              <label class="label-tipe-1" for="limbah-organik"
                                >Jumlah Total Limbah</label
                              >
                              <div class="input-group">
                                <input
                                  type="number"
                                  v-model="
                                    limbah_padat.limbah_domestik[
                                      'jumlah_total_limbah_domestik'
                                    ].dataPenyusun_desc
                                  "
                                  name="limbah-organik"
                                  id="limbah-organik"
                                  class="form-control form-medium"
                                />
                                <div class="icon-group">kg/hari</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Soal 3 -->
                      <!-- Limbah Medis -->
                      <div class="border-soal">
                        <div class="title-soal-form">
                          <h6>Limbah Medis</h6>
                        </div>
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah Dilakukan Pemilahan ?</p>
                          </div>
                          <v-radio-group
                            row
                            dense
                            hide-details
                            class="ma-0"
                            v-model="
                              limbah_padat.limbah_medis[
                                'apakah_dilakukan_pemilahan_limbah_medis?'
                              ].dataPenyusun_desc
                            "
                          >
                            <v-radio
                              color="#00ccb6"
                              label="Ya"
                              value="1"
                            ></v-radio>
                            <v-radio
                              color="#00ccb6"
                              label="Tidak"
                              value="2"
                            ></v-radio>
                          </v-radio-group>
                        </div>

                        <!-- IF Medis YA -->
                        <div
                          v-if="
                            limbah_padat.limbah_medis[
                              'apakah_dilakukan_pemilahan_limbah_medis?'
                            ].dataPenyusun_desc == 1
                          "
                        >
                          <!-- Infeksius -->
                          <div class="added-form mt-4">
                            <div class="border-soal">
                              <div class="title-soal-form bg-next-title">
                                <h6>Limbah Infeksius</h6>
                              </div>
                              <div class="soal-form bors-sol">
                                <div class="questions">
                                  <p>Apakah Dilakukan Pemilahan ?</p>
                                </div>
                                <v-radio-group
                                  row
                                  dense
                                  hide-details
                                  class="ma-0"
                                  v-model="
                                    limbah_padat.limbah_infeksius[
                                      'apakah_dilakukan_pemilahan_infeksius'
                                    ].dataPenyusun_desc
                                  "
                                >
                                  <v-radio
                                    color="#00ccb6"
                                    label="Ya"
                                    value="1"
                                  ></v-radio>
                                  <v-radio
                                    color="#00ccb6"
                                    label="Tidak"
                                    value="2"
                                  ></v-radio>
                                </v-radio-group>
                              </div>

                              <!-- IF Infeksius Yes -->
                              <div
                                class="added-form"
                                v-if="
                                  limbah_padat.limbah_infeksius[
                                    'apakah_dilakukan_pemilahan_infeksius'
                                  ].dataPenyusun_desc == 1
                                "
                              >
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahInfeksius"
                                    >Limbah Infeksius</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_infeksius[
                                          'limbah_infeksius'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahInfeksius"
                                      id="rataTimbulanLimbahInfeksius"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahTajam"
                                    >Limbah Tajam</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_infeksius[
                                          'limbah_tajam'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahTajam"
                                      id="rataTimbulanLimbahTajam"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahPatologi"
                                    >Limbah Patologi</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_infeksius[
                                          'limbah_patologi'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahPatologi"
                                      id="rataTimbulanLimbahPatologi"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                              </div>

                              <!-- IF Infeksius Tidak -->
                              <div
                                class="added-form"
                                v-if="
                                  limbah_padat.limbah_infeksius[
                                    'apakah_dilakukan_pemilahan_infeksius'
                                  ].dataPenyusun_desc == 2
                                "
                              >
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="totalLimbahInfekius"
                                    >Jumlah Total Limbah</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_infeksius[
                                          'jumlah_total_limbah'
                                        ].dataPenyusun_desc
                                      "
                                      name="totalLimbahInfekius"
                                      id="totalLimbahInfekius"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Non Infeksius -->
                          <div class="added-form mt-4">
                            <div class="border-soal">
                              <div class="title-soal-form bg-next-title">
                                <h6>Limbah Non-Infeksius</h6>
                              </div>
                              <div class="soal-form bors-sol">
                                <div class="questions">
                                  <p>Apakah Dilakukan Pemilahan ?</p>
                                </div>
                                <v-radio-group
                                  row
                                  dense
                                  hide-details
                                  class="ma-0"
                                  v-model="
                                    limbah_padat.limbah_non_infeksius[
                                      'apakah_dilakukan_pemilahan_noninfeksius'
                                    ].dataPenyusun_desc
                                  "
                                >
                                  <v-radio
                                    color="#00ccb6"
                                    label="Ya"
                                    value="1"
                                  ></v-radio>
                                  <v-radio
                                    color="#00ccb6"
                                    label="Tidak"
                                    value="2"
                                  ></v-radio>
                                </v-radio-group>
                              </div>

                              <!-- IF Non-Infeksius Yes -->
                              <div
                                class="added-form"
                                v-if="
                                  limbah_padat.limbah_non_infeksius[
                                    'apakah_dilakukan_pemilahan_noninfeksius'
                                  ].dataPenyusun_desc == 1
                                "
                              >
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahFarmasi"
                                    >Limbah Farmasi</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_non_infeksius[
                                          'limbah_farmasi'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahFarmasi"
                                      id="rataTimbulanLimbahFarmasi"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahKimia"
                                    >Limbah Kimia</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_non_infeksius[
                                          'limbah_kimia'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahKimia"
                                      id="rataTimbulanLimbahKimia"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahSitotoksik"
                                    >Limbah Sitotoksis</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_non_infeksius[
                                          'limbah_sitotoksik'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahSitotoksik"
                                      id="rataTimbulanLimbahSitotoksik"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="rataTimbulanLimbahRadioAktif"
                                    >Radioaktif</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_non_infeksius[
                                          'limbah_radioaktif'
                                        ].dataPenyusun_desc
                                      "
                                      name="rataTimbulanLimbahRadioAktif"
                                      id="rataTimbulanLimbahRadioAktif"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                              </div>

                              <!-- IF Non-Infeksius Tidak -->
                              <div
                                class="added-form"
                                v-if="
                                  limbah_padat.limbah_non_infeksius[
                                    'apakah_dilakukan_pemilahan_noninfeksius'
                                  ].dataPenyusun_desc == 2
                                "
                              >
                                <div class="d-flex align-center per-form-added">
                                  <label
                                    class="label-tipe-1"
                                    for="totalLimbahNoninfekius"
                                    >Jumlah Total Limbah</label
                                  >
                                  <div class="input-group">
                                    <input
                                      type="number"
                                      v-model="
                                        limbah_padat.limbah_non_infeksius[
                                          'jumlah_total_limbah_non_infeksius'
                                        ].dataPenyusun_desc
                                      "
                                      name="totalLimbahNoninfekius"
                                      id="totalLimbahNoninfekius"
                                      class="form-control form-medium"
                                    />
                                    <div class="icon-group">kg/hari</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- V Else Soal 0 = Tidak -->
                    <div
                      v-if="
                        limbah_padat.limbah_padat[
                          'apakah_dilakukan_pemilahan_limbah_padat?'
                        ].dataPenyusun_desc == 2
                      "
                    >
                      <div class="border-soal">
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <label class="label-tipe-1" for="limbah-organik"
                              >Jumlah Total Limbah</label
                            >
                            <div class="input-group">
                              <input
                                type="number"
                                v-model="
                                  limbah_padat.limbah_padat[
                                    'jumlah_total_limbah_padat'
                                  ].dataPenyusun_desc
                                "
                                name="limbah-organik"
                                id="limbah-organik"
                                class="form-control form-medium"
                              />
                              <div class="icon-group">kg/hari</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>

                <!-- Tab 2 -->
                <v-tab-item value="tabs2">
                  <v-card class="mh-300 custom_card pa-5" v-if="sumber_daya">
                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Unit Khusus / Organisasi Kesehatan Lingkungan</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat unit khusus kesehatan lingkungan ?
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya
                              .unit_khusus___organisasi_kesehatan_lingkungan[
                              'apakah_terdapat_unit_khusus_kesehatan_lingkungan?'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 2 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Petugas Kesehatan Lingkungan</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Jumlah Petugas Kesehatan Lingkungan</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                sumber_daya.petugas_kesehatan_lingkungan[
                                  'jumlah_petugas_kesehatan_lingkungan'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Orang</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 3 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Petugas Pengelola Limbah B3</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Jumlah petugas pengelola limbah</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                sumber_daya.petugas_pengelola_limbah_b3[
                                  'jumlah_petugas_pengelola_limbah'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Orang</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 4 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Alokasi Dana / Anggaran (Tahunan)</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Jumlah Dana / anggaran kelola limbah</label
                          >
                          <div class="input-group">
                            <div class="icon-group">Rp</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.alokasi_dana___anggaran[
                                  'jumlah_dana___anggaran_kelola_limbah'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Jumlah Dana / anggaran kesehatan lingkungan</label
                          >
                          <div class="input-group">
                            <div class="icon-group">Rp</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.alokasi_dana___anggaran[
                                  'jumlah_dana___anggaran_kesehatan_lingkungan'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Jumlah Dana / Anggaran keseluruhan fasyankes</label
                          >
                          <div class="input-group">
                            <div class="icon-group">Rp</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.alokasi_dana___anggaran[
                                  'jumlah_dana___anggaran_keseluruhan_fasyankes'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 5 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Dokumen Izin Lingkungan</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat dokumen izin lingkungan ?</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya.dokumen_izin_lingkungan[
                              'apakah_terdapat_dokumen_izin_lingkungan?'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">Tahun</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.dokumen_izin_lingkungan[
                                  'tahun_izin_dokumen_lingkungan'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.dokumen_izin_lingkungan[
                                  'nomor_izin_dokumen_lingkungan'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 6 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>
                          Kebijakan Tertulis (SE/SK) Mengenai Pengelolaan Limbah
                          Medis/B3
                        </h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Apakah terdapat dokumen izin lingkungan ?</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            sumber_daya
                              .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                              'apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">Tahun</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya
                                  .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                                  'tahun_kebijakan_dokumen_lingkungan'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya
                                  .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                                  'nomor_kebijakan_dokumen_lingkungan'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 7 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Prosedur Pengelolaan Limbah (SOP)</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah terdapat Prosedur pengelolaan limbah (SOP)?
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            sumber_daya.prosedur_pengelolaan_limbah__sop_[
                              'apakah_terdapat_prosedur_pengelolaan_limbah__sop___'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">Tahun</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.prosedur_pengelolaan_limbah__sop_[
                                  'tahun_sop'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              v-model="
                                sumber_daya.prosedur_pengelolaan_limbah__sop_[
                                  'nomor_sop'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 8 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Penyediaan Wadah</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Kualitas wadah ?</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya.penyediaan_wadah['kualitas_wadah']
                              .dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Memadai"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Memadai"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="info-form">
                            <img src="@/assets/img/info-icon.svg" alt="" />
                            <h6>Memadai :</h6>
                            <p>
                              Tersedia disemua tempat penghasil limbah fasyankes
                              dengan jumlah dan kapasitas wadah yang memadai
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 9 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Penyediaan Alat Angkut</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Kualitas alat angkut</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya.penyediaan_alat_angkut[
                              'kualitas_alat_angkut'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Memadai"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Memadai"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="info-form">
                            <img src="@/assets/img/info-icon.svg" alt="" />
                            <h6>Memadai :</h6>
                            <p>Jumlah dan kapasitas yang memadai</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 10 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Penyediaan Alat Pelindung Diri (APD)</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Kualitas APD</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya.penyediaan_alat_pelindung_diri__apd_[
                              'kualitas_apd'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Memadai"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Memadai"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="info-form">
                            <img src="@/assets/img/info-icon.svg" alt="" />
                            <h6>Memadai :</h6>
                            <p>Jumlah dan kapasitas yang memadai</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 11 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Timbangan Medis</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Ketersediaan dan status timbangan limbah medis</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya.timbangan_medis[
                              'ketersediaan_dan_status_timbangan_limbah_medis'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada - Berfungsi"
                            value="2"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Ada - Tidak Berfungsi"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 12 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Pencatatan Limbah Medis (Log Book)</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Apakah Terdapat pencatatan limbah medis (log book)
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya.pencatatan_limbah_medis__log_book_[
                              'apakah_terdapat_pencatatan_limbah_medis__log_book_'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 13 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Cold storage / Freezer Khusus Limbah Medis</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Ketersediaan dan status Cold storage / freezer
                            khusus limbah medis
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            sumber_daya
                              .cold_storage___freezer_khusus_limbah_medis[
                              'ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada - Berfungsi"
                            value="2"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Ada - Tidak Berfungsi"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 14 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Kapasitas Cold Storage / Freezer</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Jumlah Kapasitas cold storage / freezer
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                sumber_daya.kapasitas_cold_storage___freezer[
                                  'jumlah_kapasitas_cold_storage___freezer'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">m³</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>

                <!-- Tab 3 -->
                <v-tab-item value="tabs3">
                  <v-card class="mh-300 custom_card pa-5" v-if="alur">
                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Jenis Pewadahan Limbah Domestik</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Ketersediaan plastik hitam untuk limbah domestik
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.jenis_pewadahan_limbah_domestik[
                              'ketersediaan_plastik_hitam_untuk_limbah_domestik'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 2 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Jenis Pewadahan Limbah Medis Infeksius</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Ketersediaan plastik kuning untuk limbah medis
                            infeksius dan patologi
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.jenis_pewadahan_limbah_medis_infeksius[
                              'ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Ketersediaan safety box untuk limbah tajam</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.jenis_pewadahan_limbah_medis_infeksius[
                              'ketersediaan_safety_box_untuk_limbah_tajam'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 3 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Jenis Pewadahan Limbah Medis Non-Infeksius</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>
                            Ketersediaan plastik warna ungu untuk sitotoksis
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.jenis_pewadahan_limbah_medis_non_infeksius[
                              'ketersediaan_plastik_warna_ungu_untuk_sitotoksis'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Ketersediaan plastik warna merah untuk radioaktif
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.jenis_pewadahan_limbah_medis_non_infeksius[
                              'ketersediaan_plastik_warna_merah_untuk_radioaktif'
                            ].dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Ketersediaan plastik warna coklat untuk farmasi</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.jenis_pewadahan_limbah_medis_non_infeksius[
                              'ketersediaan_plastik_warna_coklat_untuk_farmasi'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Ketersediaan plastik warna coklat untuk kimia</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.jenis_pewadahan_limbah_medis_non_infeksius[
                              'ketersediaan_plastik_warna_coklat_untuk_kimia'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 4 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Spesifikasi Pewadahan Plastik</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Tertutup</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.spesifikasi_pewadahan_plastik['tertutup']
                              .dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tidak Bocor</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pewadahan_plastik['tidak_bocor']
                              .dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Label Sesuai</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pewadahan_plastik['label_sesuai']
                              .dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Simbol sesuai</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pewadahan_plastik['simbol_sesuai']
                              .dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Mudah dibersihkan</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pewadahan_plastik[
                              'mudah_dibersihkan'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Warna sesuai</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          class="ma-0"
                          v-model="
                            alur.spesifikasi_pewadahan_plastik['warna_sesuai']
                              .dataPenyusun_desc
                          "
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 5 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Spesifikasi Pengangkutan ke Tempat Penyimpanan</h6>
                      </div>
                      <div class="soal-form bors-sol">
                        <div class="questions">
                          <p>Khusus alat angkut khusus limbah</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'khusus_alat_angkut_khusus_limbah'
                            ].dataPenyusun_desc
                          "
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Wheel bin/wadah beroda</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'wheel_bin_wadah_beroda'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tertutup</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'tertutup'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Jalur khusus</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'jalur_khusus'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Waktu khusus</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'waktu_khusus'
                            ].dataPenyusun_desc
                          "
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tidak bocor</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'tidak_bocor'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Label sesuai</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'label_sesuai'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Simbol sesuai</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'simbol_sesuai'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Mudah dibersihkan</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'mudah_dibersihkan'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Warna sesuai</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                              'warna_sesuai'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 6 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Ketersediaan ruang penyimpanan</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Di Tempat Penyimpanan Sementara (TPS) Limbah B3
                            berizin (Dalam area fasyankes)
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          v-model="
                            alur.ketersediaan_ruang_penyimpanan[
                              'di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_'
                            ].dataPenyusun_desc
                          "
                          hide-details
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo
                            pemindahan berizin (diluar area fasyankes)
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.ketersediaan_ruang_penyimpanan[
                              'di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Lainnya (Di gudang, ruang kosong, ruang terbuka,
                            dll)
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.ketersediaan_ruang_penyimpanan[
                              'lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Tidak ada penyimpanan</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.ketersediaan_ruang_penyimpanan[
                              'tidak_ada_penyimpanan'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 7 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>
                          Ketersediaan pengangkutan limbah ke tempat pengolah
                        </h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Jumlah kendaraan roda tiga</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur
                              .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                              'jumlah_kendaraan_roda_tiga'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Memadai"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Memadai"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Jumlah kendaraan roda empat</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur
                              .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                              'jumlah_kendaraan_roda_empat'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Memadai"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Memadai"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Jumlah kendaraan perusahaan pengangkut</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur
                              .ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                              'jumlah_kendaraan_perusahaan_pengangkut'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Memadai"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Memadai"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>

                    <!-- Soal 8 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Ketersediaan Alat Pengolah Limbah Di Fasyankes</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah terdapat alat pengolah limbah insinerator
                          </p>
                        </div>

                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.ketersediaan_alat_pengolah_limbah[
                              'apakah_terdapat_pengolah_limbah_insinerator'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Kapasitas Operasional:
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                alur.ketersediaan_alat_pengolah_limbah[
                                  'kapasitas_operasinal_insinerator'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Kg/jam</div>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah terdapat alat pengolah limbah
                            autoclave/microwave
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.ketersediaan_alat_pengolah_limbah[
                              'apakah_terdapat_pengolah_limbah_autoclave_microwave'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ada"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak Ada"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasPengelolaLimbah"
                          >
                            Kapasitas Operasional:
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                alur.ketersediaan_alat_pengolah_limbah[
                                  'kapasitas_operasinal_autoclave_microwave'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasPengelolaLimbah"
                              id="jumlahPetugasPengelolaLimbah"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Kg/jam</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 9 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Spesifikasi pengolahan limbah</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Diolah dengan insinerator</p>
                        </div>

                        <v-radio-group
                          :disabled="
                            alur.spesifikasi_pengolahan_limbah[
                              'apakah_ingin_menggunakan_data_sebelumnya_'
                            ].dataPenyusun_desc != 2
                          "
                          row
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengolahan_limbah[
                              'diolah_dengan_insinerator'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'diolah_dengan_insinerator'
                                ].dataPenyusun_desc != 1
                              "
                              v-model="
                                alur.spesifikasi_pengolahan_limbah[
                                  'no_insinerator'
                                ].dataPenyusun_desc
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group" style="margin-right: 50px">
                              Tanggal izin
                            </div>
                            <v-menu
                              v-model="calendar1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'diolah_dengan_insinerator'
                                ].dataPenyusun_desc != 1
                              "
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  v-model="
                                    alur.spesifikasi_pengolahan_limbah[
                                      'tangggal_izin_insinerator'
                                    ].dataPenyusun_desc
                                  "
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="form-control form-medium"
                                />
                              </template>
                              <v-date-picker
                                @input="calendar1 = false"
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'tangggal_izin_insinerator'
                                  ].dataPenyusun_desc
                                "
                              ></v-date-picker>
                            </v-menu>
                            <v-icon class="ml-2">mdi-calendar</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Apakah terdapat alat pengolah limbah
                            autoclave/microwave
                          </p>
                        </div>
                        <v-radio-group
                          row
                          :disabled="
                            alur.spesifikasi_pengolahan_limbah[
                              'apakah_ingin_menggunakan_data_sebelumnya_'
                            ].dataPenyusun_desc != 2
                          "
                          dense
                          hide-details
                          v-model="
                            alur.spesifikasi_pengolahan_limbah[
                              'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              v-model="
                                alur.spesifikasi_pengolahan_limbah[
                                  'no_autoclave_microwave'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                                ].dataPenyusun_desc != 1
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group" style="margin-right: 50px">
                              Tanggal izin
                            </div>
                            <v-menu
                              v-model="calendar2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'apakah_terdapat_alat_pengolah_limbah_autoclave_microwave'
                                ].dataPenyusun_desc != 1
                              "
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                  v-model="
                                    alur.spesifikasi_pengolahan_limbah[
                                      'tangggal_izin_autoclave_microwave'
                                    ].dataPenyusun_desc
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  class="form-control form-medium"
                                />
                              </template>
                              <v-date-picker
                                @input="calendar2 = false"
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'tangggal_izin_autoclave_microwave'
                                  ].dataPenyusun_desc
                                "
                              ></v-date-picker>
                            </v-menu>
                            <v-icon class="ml-2">mdi-calendar</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Bekerja sama dengan pengolah limbah medis</p>
                        </div>

                        <v-radio-group
                          row
                          dense
                          :disabled="
                            alur.spesifikasi_pengolahan_limbah[
                              'apakah_ingin_menggunakan_data_sebelumnya_'
                            ].dataPenyusun_desc != 2
                          "
                          hide-details
                          v-model="
                            alur.spesifikasi_pengolahan_limbah[
                              'bekerja_sama_dengan_pengolah_limbah_medis'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <div class="input-group">
                            <input
                              v-model="
                                alur.spesifikasi_pengolahan_limbah[
                                  'nama_perusahaan_pengolah_limbah_sesuai_dengan_pks'
                                ].dataPenyusun_desc
                              "
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'bekerja_sama_dengan_pengolah_limbah_medis'
                                ].dataPenyusun_desc != 1
                              "
                              placeholder="Nama Perusahaan pengolah limbah sesuai dengan PKS"
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control long_input"
                            />
                          </div>
                          <div class="px-3"></div>
                          <div class="input-group">
                            <div class="icon-group">No</div>
                            <input
                              type="number"
                              v-model="
                                alur.spesifikasi_pengolahan_limbah['no_pks']
                                  .dataPenyusun_desc
                              "
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'bekerja_sama_dengan_pengolah_limbah_medis'
                                ].dataPenyusun_desc != 1
                              "
                              name="totalLimbahNoninfekius"
                              id="totalLimbahNoninfekius"
                              class="form-control form-medium"
                            />
                          </div>
                          <div class="px-3">-</div>
                          <div class="input-group">
                            <div class="icon-group" style="margin-right: 50px">
                              Tanggal PKS
                            </div>
                            <v-menu
                              v-model="calendar3"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              :disabled="
                                alur.spesifikasi_pengolahan_limbah[
                                  'bekerja_sama_dengan_pengolah_limbah_medis'
                                ].dataPenyusun_desc != 1
                              "
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <input
                                  type="text"
                                  placeholder="mm/dd/yyyy"
                                  v-model="
                                    alur.spesifikasi_pengolahan_limbah[
                                      'tanggal_pks'
                                    ].dataPenyusun_desc
                                  "
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  class="form-control form-medium"
                                />
                              </template>
                              <v-date-picker
                                v-model="
                                  alur.spesifikasi_pengolahan_limbah[
                                    'tanggal_pks'
                                  ].dataPenyusun_desc
                                "
                                @input="calendar3 = false"
                              ></v-date-picker>
                            </v-menu>
                            <v-icon class="ml-2">mdi-calendar</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Diolah dengan metode lainnya</p>
                        </div>

                        <v-radio-group
                          row
                          dense
                          :disabled="
                            alur.spesifikasi_pengolahan_limbah[
                              'apakah_ingin_menggunakan_data_sebelumnya_'
                            ].dataPenyusun_desc != 2
                          "
                          hide-details
                          v-model="
                            alur.spesifikasi_pengolahan_limbah[
                              'diolah_dengan_metode_lainnya'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                    <!-- Soal 10 -->
                    <div
                      class="border-soal"
                      v-if="
                        alur.spesifikasi_pengolahan_limbah[
                          'diolah_dengan_metode_lainnya'
                        ].dataPenyusun_desc == 1
                      "
                    >
                      <div class="title-soal-form">
                        <h6>Pengolahan limbah metode lainnya</h6>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Disinfeksi untuk limbah infeksius padat</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.pengolahan_limbah_metode_lainnya[
                              'disinfeksi_untuk_limbah_infeksius_padat'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>
                            Inertifikasi / solidifikasi untuk limbah farmasi dan
                            kimia padat
                          </p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.pengolahan_limbah_metode_lainnya[
                              'inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Enkapsulasi untuk limbah tajam</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.pengolahan_limbah_metode_lainnya[
                              'enkapsulasi_untuk_limbah_tajam'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                      <div class="soal-form bors-sol mt-3">
                        <div class="questions">
                          <p>Penguburan limbah patologi</p>
                        </div>
                        <v-radio-group
                          row
                          dense
                          hide-details
                          v-model="
                            alur.pengolahan_limbah_metode_lainnya[
                              'penguburan_limbah_patologi'
                            ].dataPenyusun_desc
                          "
                          class="ma-0"
                        >
                          <v-radio
                            color="#00ccb6"
                            label="Ya"
                            value="1"
                          ></v-radio>
                          <v-radio
                            color="#00ccb6"
                            label="Tidak"
                            value="2"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>

                <!-- Tab 4 -->
                <v-tab-item value="tabs4">
                  <v-card class="mh-300 custom_card pa-5" v-if="limbah_cair">
                    <!-- Soal 1 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Debit</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Rata rata debit air masuk IPAL</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                limbah_cair.debit[
                                  'rata_rata_debit_air_masuk_ipal'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Liter/hari</div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Rata rata debit air keluar IPAL</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                limbah_cair.debit[
                                  'rata_rata_debit_air_keluar_ipal'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">Liter/hari</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 2 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Kualitas air Limbah</h6>
                      </div>
                      <div class="added-form">
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Rata rata pH harian
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                limbah_cair.kualitas_air_limbah[
                                  'rata_rata_ph_harian'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">1-4</div>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Rata rata suhu harian</label
                          >
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                limbah_cair.kualitas_air_limbah[
                                  'rata_rata_suhu_harian'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">℃</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 3 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Instalasi pengelolaan air Limbah (IPAL)</h6>
                      </div>
                      <div class="added-form">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>
                              Apakah terdapat Instalasi pengelolaan air limbah
                              (IPAL)
                            </p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              row
                              dense
                              hide-details
                              v-model="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                  'ketersediaan_instalasi_pengeolaan_air_limbah__ipal_'
                                ].dataPenyusun_desc
                              "
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              ></v-radio>
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="2"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                        <div class="d-flex align-center per-form-added">
                          <label
                            class="label-tipe-2"
                            for="jumlahPetugasKesehatanLingkungan"
                          >
                            Jumlah kapasitas IPAL
                          </label>
                          <div class="input-group">
                            <input
                              type="number"
                              v-model="
                                limbah_cair
                                  .instalasi_pengelolaan_air_limbah__ipal_[
                                  'jumlah_kapasitas_ipal'
                                ].dataPenyusun_desc
                              "
                              name="jumlahPetugasKesehatanLingkungan"
                              id="jumlahPetugasKesehatanLingkungan"
                              class="form-control form-medium"
                            />
                            <div class="icon-group">m<sup>3</sup></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 4 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Izin pembuangan limbah cair (IPLC)</h6>
                      </div>
                      <div class="added-form">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>
                              Apakah terdapat Izin pembuangan limbah cair (IPLC)
                            </p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              row
                              dense
                              hide-details
                              v-model="
                                limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                                  'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                                ].dataPenyusun_desc
                              "
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              ></v-radio>
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="2"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div class="icon-group">No</div>
                              <input
                                type="number"
                                v-model="
                                  limbah_cair
                                    .izin_pembuangan_limbah_cair__iplc_[
                                    'no_iplc'
                                  ].dataPenyusun_desc
                                "
                                :disabled="
                                  limbah_cair
                                    .izin_pembuangan_limbah_cair__iplc_[
                                    'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                                  ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                id="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              />
                            </div>
                            <div class="px-3">-</div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar4"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  limbah_cair
                                    .izin_pembuangan_limbah_cair__iplc_[
                                    'apakah_terdapat_izin_pembuangan_limbah_cair__iplc_'
                                  ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <input
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-model="
                                      limbah_cair
                                        .izin_pembuangan_limbah_cair__iplc_[
                                        'tanggal_izin_iplc'
                                      ].dataPenyusun_desc
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    class="form-control form-medium"
                                  />
                                </template>
                                <v-date-picker
                                  @input="calendar4 = false"
                                  v-model="
                                    limbah_cair
                                      .izin_pembuangan_limbah_cair__iplc_[
                                      'tanggal_izin_iplc'
                                    ].dataPenyusun_desc
                                  "
                                ></v-date-picker>
                              </v-menu>
                              <v-icon class="ml-2">mdi-calendar</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Soal 5 -->
                    <div class="border-soal">
                      <div class="title-soal-form">
                        <h6>Baku mutu limbah cair</h6>
                      </div>
                      <div class="added-form">
                        <div class="soal-form bors-sol">
                          <div class="questions">
                            <p>Apakah baku mutu limbah cair sesuai</p>
                          </div>
                          <div class="d-flex align-center">
                            <v-radio-group
                              row
                              dense
                              hide-details
                              v-model="
                                limbah_cair.baku_mutu_limbah_cair[
                                  'apakah_baku_mutu_limbah_cair_sesuai'
                                ].dataPenyusun_desc
                              "
                              class="ma-0"
                            >
                              <v-radio
                                color="#00ccb6"
                                label="Ya"
                                value="1"
                              ></v-radio>
                              <v-radio
                                color="#00ccb6"
                                label="Tidak"
                                value="2"
                              ></v-radio>
                            </v-radio-group>
                          </div>
                        </div>
                        <div class="added-form">
                          <div class="d-flex align-center per-form-added">
                            <div class="input-group">
                              <div class="icon-group">No</div>
                              <input
                                type="number"
                                v-model="
                                  limbah_cair.baku_mutu_limbah_cair[
                                    'no_mutu_limbah_cair'
                                  ].dataPenyusun_desc
                                "
                                :disabled="
                                  limbah_cair.baku_mutu_limbah_cair[
                                    'apakah_baku_mutu_limbah_cair_sesuai'
                                  ].dataPenyusun_desc != 1
                                "
                                name="totalLimbahNoninfekius"
                                id="totalLimbahNoninfekius"
                                class="form-control form-medium"
                              />
                            </div>
                            <div class="px-3">-</div>
                            <div class="input-group">
                              <div
                                class="icon-group"
                                style="margin-right: 50px"
                              >
                                Tanggal izin
                              </div>
                              <v-menu
                                v-model="calendar5"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                :disabled="
                                  limbah_cair.baku_mutu_limbah_cair[
                                    'apakah_baku_mutu_limbah_cair_sesuai'
                                  ].dataPenyusun_desc != 1
                                "
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <input
                                    type="text"
                                    placeholder="mm/dd/yyyy"
                                    readonly
                                    v-model="
                                      limbah_cair.baku_mutu_limbah_cair[
                                        'tanggal_mutu_limbah_cair'
                                      ].dataPenyusun_desc
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    class="form-control form-medium"
                                  />
                                </template>
                                <v-date-picker
                                  @input="calendar5 = false"
                                  v-model="
                                    limbah_cair.baku_mutu_limbah_cair[
                                      'tanggal_mutu_limbah_cair'
                                    ].dataPenyusun_desc
                                  "
                                ></v-date-picker>
                              </v-menu>
                              <v-icon class="ml-2">mdi-calendar</v-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </div>
          <div class="pa-2">
            <success-alert v-bind:dialogSuccess="dialogSuccess" />
            <v-overlay
              :absolute="true"
              color="white"
              style="height: 100vh; position: fixed"
              opacity="1"
              :value="loading"
            >
              <v-progress-circular
                indeterminate
                class="mr-2"
                color="#00b4cc"
              ></v-progress-circular>
              <b class="color_default">Loading...</b>
            </v-overlay>
            <Alert
              v-bind:dialogAlert="dialogAlert"
              v-bind:temporary="temporary"
              v-bind:body="tobeSent"
              @close="dialogAlert = false"
              @save="savePermanent()"
            />
          </div>
          <div class="paginat">
            <div class="button-pag">
              <div>
                <v-btn
                  color="white"
                  dark
                  class="pa-3 custom_shadow rounded-lg text-capitalize"
                  @click="tabKembali"
                  ><v-icon small color="#00b4cc"
                    >mdi-arrow-left-circle-outline</v-icon
                  >
                  <p class="ma-0 color_default ml-2">Kembali</p></v-btn
                >
              </div>
              <div>
                <p class="ma-0 color_default">{{ paginNow }} / 4</p>
              </div>

              <div>
                <v-btn
                  v-if="tabs != 'tabs4'"
                  min-width="10px"
                  color="#00b4cc"
                  dark
                  class="pa-2 custom_shadow rounded-lg text-capitalize"
                  @click="tabLanjut"
                >
                  <p class="ma-0 mr-2">Lanjut</p>
                  <v-icon small>mdi-arrow-right-circle-outline</v-icon>
                </v-btn>

                <v-btn
                  v-if="tabs == 'tabs4'"
                  min-width="10px"
                  color="#00b4cc"
                  dark
                  class="pa-2 custom_shadow rounded-lg text-capitalize"
                  @click="setupForm(false)"
                >
                  <p class="ma-0 mr-2">Kirim</p>
                  <v-icon small>mdi-tray-arrow-up</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderFormulir from "../../../../components/Formulir/HeaderFormulir.vue";
import Alert from "../../../../components/Formulir/Modal/alert.vue";
import SuccessAlert from "../../../../components/Formulir/Modal/successAlert.vue";

export default {
  name: "Fasyankes",
  components: { HeaderFormulir, Alert, SuccessAlert },
  computed: {
    ...mapState({
      profile: (state) => state.user.profile,
      data_form: (state) => state.formulir.limbah_fasyankes,
      getBor: (state) => state.getBor,
      reportId: (state) => state.reportId,
    }),
    alur() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.alur_pengelolaan_limbah_padat;
      }
      return data;
    },
    limbah_padat() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.data_limbah_padat;
      }
      return data;
    },
    limbah_cair() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.pengelolaan_limbah_cair;
      }
      return data;
    },
    sumber_daya() {
      let data = null;
      if (this.data_form) {
        data = this.data_form.limbah_fasyankes.sumber_daya_dan_organisasi;
      }
      return data;
    },
  },
  data() {
    return {
      loading: false,
      isiFormulir: true,
      tab: 2,
      tabs: "tabs1",
      menu: false,
      role: "",
      users: false,
      menuLimit: false,
      paginNow: 1,
      formulir: {
        bor: 1,
        pemilahanDataLimbahPadat: null,
        pemilihanLimbahDomestik: null,
        limbahOrganik: null,
        limbahAnorganik: null,
        totalLimbahDomestik: null,
        limbahmedis: null,
        noninfeksius: null,
        rataTimbulanLimbahInfeksius: null,
        rataTimbulanLimbahTajam: null,
        rataTimbulanLimbahPatologi: null,
        totalLimbahInfekius: null,
        rataTimbulanLimbahFarmasi: null,
        rataTimbulanLimbahKimia: null,
        rataTimbulanLimbahSitotoksik: null,
        rataTimbulanLimbahRadioAktif: null,
        totalLimbahNoninfekius: null,
        KetersediaanUnit: null,
      },
      id: null,
      dialogSuccess: false,
      dialogAlert: false,
      temporary: true,
      loading: false,
      loadTempo: false,
      date: null,
      tobeSent: null,
      calendar1: false,
      calendar2: false,
      calendar3: false,
      calendar4: false,
      calendar5: false,
      dataConfirm: null,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getData();
    this.getDataConfrim();
  },
  methods: {
    getData() {
      this.loading = true;
      let body = {
        report_id: this.id,
      };
      this.$store
        .dispatch("formulir/getFormulirFasyankes", body)
        .then((data) => {
          this.date = data.data.date;
          this.loading = false;
        });
    },
    getDataConfrim() {
      this.loading = true;
      this.$store
        .dispatch("formulir/getDetailConfirm", this.id)
        .then((data) => {
          let obj = {};
          data.verification.forEach((el) => {
            if (el.kode_indikator.kodeIndikator_name == "Data Limbah Padat") {
              el.verification_kode_indikator_waste_data.forEach((i) => {
                i.status = "1";
              });
              obj["tab1"] = el;
            } else if (
              el.kode_indikator.kodeIndikator_name ==
              "Sumber Daya dan organisasi"
            ) {
              el.verification_kode_indikator_waste_data.forEach((i) => {
                i.status = "1";
              });
              obj["tab2"] = el;
            } else if (
              el.kode_indikator.kodeIndikator_name ==
              "Alur Pengelolaan limbah padat"
            ) {
              el.verification_kode_indikator_waste_data.forEach((i) => {
                i.status = "1";
              });
              obj["tab3"] = el;
            } else if (
              el.kode_indikator.kodeIndikator_name == "Pengelolaan Limbah Cair"
            ) {
              el.verification_kode_indikator_waste_data.forEach((i) => {
                i.status = "1";
              });
              obj["tab4"] = el;
            }
          });
          this.dataConfirm = obj;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    temporarySave(body) {
      this.loadTempo = true;
      this.$store
        .dispatch("formulir/updateFormulirFasyankes", body)
        .then((data) => {
          console.log(data);
          this.loadTempo = false;
          this.dialogAlert = true;
        })
        .catch((err) => {
          this.loadTempo = false;
          console.log(err);
        });
    },
    saveFormulir(body) {
      this.tobeSent = body;
      this.temporary = false;
      this.dialogAlert = true;
    },
    savePermanent() {
      this.loading = true;
      this.dialogAlert = false;
      this.$store
        .dispatch("formulir/saveFormulirFasyankes", this.tobeSent)
        .then((data) => {
          this.loading = false;
          this.dialogSuccess = true;
          setTimeout(() => {
            this.dialogSuccess = false;
            this.$router.push("/formulir");
          }, 1500);
          console.log(data);
        });
    },
    setupForm(draft) {
      console.log(this.limbah_padat);
      let body = {
        report_id: this.id,
        data: [
          // form 1
          {
            name: "Apakah dilakukan Pemilahan Limbah Padat?",

            value:
              this.limbah_padat.limbah_padat[
                "apakah_dilakukan_pemilahan_limbah_padat?"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah Total Limbah Padat",

            value:
              this.limbah_padat.limbah_padat["jumlah_total_limbah_padat"]
                .dataPenyusun_desc,
          },
          {
            name: "Apakah dilakukan Pemilahan Domestik",

            value:
              this.limbah_padat.limbah_domestik[
                "apakah_dilakukan_pemilahan_domestik"
              ].dataPenyusun_desc,
          },
          {
            name: "Limbah Organik",

            value:
              this.limbah_padat.limbah_domestik["limbah_organik"]
                .dataPenyusun_desc,
          },
          {
            name: "Limbah Non-organik",

            value:
              this.limbah_padat.limbah_domestik["limbah_non_organik"]
                .dataPenyusun_desc,
          },
          {
            name: "Jumlah Total Limbah Domestik",

            value:
              this.limbah_padat.limbah_domestik["jumlah_total_limbah_domestik"]
                .dataPenyusun_desc,
          },
          {
            name: "Apakah dilakukan Pemilahan Limbah Medis?",

            value:
              this.limbah_padat.limbah_medis[
                "apakah_dilakukan_pemilahan_limbah_medis?"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah dilakukan Pemilahan Infeksius",

            value:
              this.limbah_padat.limbah_infeksius[
                "apakah_dilakukan_pemilahan_infeksius"
              ].dataPenyusun_desc,
          },
          {
            name: "limbah Infeksius",

            value:
              this.limbah_padat.limbah_infeksius["limbah_infeksius"]
                .dataPenyusun_desc,
          },
          {
            name: "limbah Tajam",

            value:
              this.limbah_padat.limbah_infeksius["limbah_tajam"]
                .dataPenyusun_desc,
          },
          {
            name: "limbah Patologi",

            value:
              this.limbah_padat.limbah_infeksius["limbah_patologi"]
                .dataPenyusun_desc,
          },
          {
            name: "Jumlah Total limbah infeksius",

            value:
              this.limbah_padat.limbah_infeksius[
                "jumlah_total_limbah_infeksius"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah dilakukan Pemilahan Noninfeksius",

            value:
              this.limbah_padat.limbah_non_infeksius[
                "apakah_dilakukan_pemilahan_noninfeksius"
              ].dataPenyusun_desc,
          },
          {
            name: "limbah Farmasi",

            value:
              this.limbah_padat.limbah_non_infeksius["limbah_farmasi"]
                .dataPenyusun_desc,
          },
          {
            name: "limbah Kimia",

            value:
              this.limbah_padat.limbah_non_infeksius["limbah_kimia"]
                .dataPenyusun_desc,
          },
          {
            name: "limbah Sitotoksik",

            value:
              this.limbah_padat.limbah_non_infeksius["limbah_sitotoksik"]
                .dataPenyusun_desc,
          },
          {
            name: "limbah Radioaktif",

            value:
              this.limbah_padat.limbah_non_infeksius["limbah_radioaktif"]
                .dataPenyusun_desc,
          },
          {
            name: "Jumlah total limbah non infeksius",

            value:
              this.limbah_padat.limbah_non_infeksius[
                "jumlah_total_limbah_non_infeksius"
              ].dataPenyusun_desc,
          },
          // form 2
          {
            name: "Apakah terdapat unit khusus kesehatan lingkungan?",

            value:
              this.sumber_daya.unit_khusus___organisasi_kesehatan_lingkungan[
                "apakah_terdapat_unit_khusus_kesehatan_lingkungan?"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah petugas kesehatan lingkungan",

            value:
              this.sumber_daya.petugas_kesehatan_lingkungan[
                "jumlah_petugas_kesehatan_lingkungan"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah petugas pengelola limbah",

            value:
              this.sumber_daya.petugas_pengelola_limbah_b3[
                "jumlah_petugas_pengelola_limbah"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah dana / anggaran kelola limbah",

            value:
              this.sumber_daya.alokasi_dana___anggaran[
                "jumlah_dana___anggaran_kelola_limbah"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah dana / anggaran kesehatan lingkungan",

            value:
              this.sumber_daya.alokasi_dana___anggaran[
                "jumlah_dana___anggaran_kesehatan_lingkungan"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah dana / anggaran keseluruhan fasyankes",

            value:
              this.sumber_daya.alokasi_dana___anggaran[
                "jumlah_dana___anggaran_keseluruhan_fasyankes"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat dokumen izin lingkungan?",

            value:
              this.sumber_daya.dokumen_izin_lingkungan[
                "apakah_terdapat_dokumen_izin_lingkungan?"
              ].dataPenyusun_desc,
          },
          {
            name: "Tahun Izin dokumen lingkungan",

            value:
              this.sumber_daya.dokumen_izin_lingkungan[
                "tahun_izin_dokumen_lingkungan"
              ].dataPenyusun_desc,
          },
          {
            name: "Nomor Izin dokumen lingkungan",

            value:
              this.sumber_daya.dokumen_izin_lingkungan[
                "nomor_izin_dokumen_lingkungan"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat kebijakan tertulis mengenai pengelolaan limbah medis/B3 ",

            value:
              this.sumber_daya
                .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                "apakah_terdapat_kebijakan_tertulis_mengenai_pengelolaan_limbah_medis_b3_"
              ].dataPenyusun_desc,
          },
          {
            name: "Tahun kebijakan dokumen lingkungan",

            value:
              this.sumber_daya
                .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                "tahun_kebijakan_dokumen_lingkungan"
              ].dataPenyusun_desc,
          },
          {
            name: "Nomor kebijakan dokumen lingkungan",

            value:
              this.sumber_daya
                .kebijakan_tertulis__se_sk__mengenai_pengelolaan_limbah_medis_b3[
                "nomor_kebijakan_dokumen_lingkungan"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat Prosedur pengelolaan limbah (SOP)  ",

            value:
              this.sumber_daya.prosedur_pengelolaan_limbah__sop_[
                "apakah_terdapat_prosedur_pengelolaan_limbah__sop___"
              ].dataPenyusun_desc,
          },
          {
            name: "Tahun SOP",

            value:
              this.sumber_daya.prosedur_pengelolaan_limbah__sop_["tahun_sop"]
                .dataPenyusun_desc,
          },
          {
            name: "Nomor SOP",

            value:
              this.sumber_daya.prosedur_pengelolaan_limbah__sop_["nomor_sop"]
                .dataPenyusun_desc,
          },
          {
            name: "Kualitas wadah",

            value:
              this.sumber_daya.penyediaan_wadah["kualitas_wadah"]
                .dataPenyusun_desc,
          },
          {
            name: "Kualitas Alat Angkut",

            value:
              this.sumber_daya.penyediaan_alat_angkut["kualitas_alat_angkut"]
                .dataPenyusun_desc,
          },
          {
            name: "Kualitas APD",

            value:
              this.sumber_daya.penyediaan_alat_pelindung_diri__apd_[
                "kualitas_apd"
              ].dataPenyusun_desc,
          },
          {
            name: "Ketersediaan dan status timbangan limbah medis",

            value:
              this.sumber_daya.timbangan_medis[
                "ketersediaan_dan_status_timbangan_limbah_medis"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat pencatatan limbah medis (log book)",

            value:
              this.sumber_daya.pencatatan_limbah_medis__log_book_[
                "apakah_terdapat_pencatatan_limbah_medis__log_book_"
              ].dataPenyusun_desc,
          },
          {
            name: "Ketersediaan dan status Cold storage / freezer khusus limbah medis",

            value:
              this.sumber_daya.cold_storage___freezer_khusus_limbah_medis[
                "ketersediaan_dan_status_cold_storage___freezer_khusus_limbah_medis"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah Kapasitas cold storage / freezer",

            value:
              this.sumber_daya.kapasitas_cold_storage___freezer[
                "jumlah_kapasitas_cold_storage___freezer"
              ].dataPenyusun_desc,
          },
          // form 3
          {
            name: "ketersediaan plastik hitam untuk limbah domestik",

            value:
              this.alur.jenis_pewadahan_limbah_domestik[
                "ketersediaan_plastik_hitam_untuk_limbah_domestik"
              ].dataPenyusun_desc,
          },
          {
            name: "ketersediaan plastik kuning untuk limbah medis infeksius dan patologi",

            value:
              this.alur.jenis_pewadahan_limbah_medis_infeksius[
                "ketersediaan_plastik_kuning_untuk_limbah_medis_infeksius_dan_patologi"
              ].dataPenyusun_desc,
          },
          {
            name: "ketersediaan safety box untuk limbah tajam",

            value:
              this.alur.jenis_pewadahan_limbah_medis_infeksius[
                "ketersediaan_safety_box_untuk_limbah_tajam"
              ].dataPenyusun_desc,
          },
          {
            name: "ketersediaan plastik warna ungu untuk sitotoksis",

            value:
              this.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                "ketersediaan_plastik_warna_ungu_untuk_sitotoksis"
              ].dataPenyusun_desc,
          },
          {
            name: "ketersediaan plastik warna merah untuk radioaktif",

            value:
              this.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                "ketersediaan_plastik_warna_merah_untuk_radioaktif"
              ].dataPenyusun_desc,
          },
          {
            name: "ketersediaan plastik warna coklat untuk farmasi",

            value:
              this.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                "ketersediaan_plastik_warna_coklat_untuk_farmasi"
              ].dataPenyusun_desc,
          },
          {
            name: "ketersediaan plastik warna coklat untuk Kimia",

            value:
              this.alur.jenis_pewadahan_limbah_medis_non_infeksius[
                "ketersediaan_plastik_warna_coklat_untuk_kimia"
              ].dataPenyusun_desc,
          },
          {
            name: "Tertutup",

            value:
              this.alur.spesifikasi_pewadahan_plastik["tertutup"]
                .dataPenyusun_desc,
          },
          {
            name: "Tidak bocor",

            value:
              this.alur.spesifikasi_pewadahan_plastik["tidak_bocor"]
                .dataPenyusun_desc,
          },
          {
            name: "Label sesuai",

            value:
              this.alur.spesifikasi_pewadahan_plastik["label_sesuai"]
                .dataPenyusun_desc,
          },
          {
            name: "Simbol sesuai",

            value:
              this.alur.spesifikasi_pewadahan_plastik["simbol_sesuai"]
                .dataPenyusun_desc,
          },
          {
            name: "Mudah dibersihkan",

            value:
              this.alur.spesifikasi_pewadahan_plastik["mudah_dibersihkan"]
                .dataPenyusun_desc,
          },
          {
            name: "Warna sesuai",

            value:
              this.alur.spesifikasi_pewadahan_plastik["warna_sesuai"]
                .dataPenyusun_desc,
          },
          {
            name: "Khusus Alat angkut khusus limbah",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "khusus_alat_angkut_khusus_limbah"
              ].dataPenyusun_desc,
          },
          {
            name: "Wheel bin/wadah beroda",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "wheel_bin_wadah_beroda"
              ].dataPenyusun_desc,
          },
          {
            name: "Tertutup",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "tertutup"
              ].dataPenyusun_desc,
          },
          {
            name: "Jalur khusus",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "jalur_khusus"
              ].dataPenyusun_desc,
          },
          {
            name: "Waktu khusus",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "waktu_khusus"
              ].dataPenyusun_desc,
          },
          {
            name: "Tidak bocor",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "tidak_bocor"
              ].dataPenyusun_desc,
          },
          {
            name: "Label sesuai",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "label_sesuai"
              ].dataPenyusun_desc,
          },
          {
            name: "Simbol sesuai",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "simbol_sesuai"
              ].dataPenyusun_desc,
          },
          {
            name: "Mudah dibersihkan",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "mudah_dibersihkan"
              ].dataPenyusun_desc,
          },
          {
            name: "Warna sesuai",

            value:
              this.alur.spesifikasi_pengangkutan_ke_tempat_penyimpanan[
                "warna_sesuai"
              ].dataPenyusun_desc,
          },

          {
            name: "Di Tempat Penyimpanan Sementara (TPS) Limbah B3 berizin (Dalam area fasyankes)",

            value:
              this.alur.ketersediaan_ruang_penyimpanan[
                "di_tempat_penyimpanan_sementara__tps__limbah_b3_berizin__dalam_area_fasyankes_"
              ].dataPenyusun_desc,
          },
          {
            name: "Di Tempat Penyimpanan Sementara (TPS) Limbah B3 depo pemindahan berizin (diluar area fasyankes)",

            value:
              this.alur.ketersediaan_ruang_penyimpanan[
                "di_tempat_penyimpanan_sementara__tps__limbah_b3_depo_pemindahan_berizin__diluar_area_fasyankes_"
              ].dataPenyusun_desc,
          },
          {
            name: "Lainnya (Di gudang, ruang kosong, ruang terbuka, dll)",

            value:
              this.alur.ketersediaan_ruang_penyimpanan[
                "lainnya__di_gudang,_ruang_kosong,_ruang_terbuka,_dll_"
              ].dataPenyusun_desc,
          },
          {
            name: "Tidak ada penyimpanan",

            value:
              this.alur.ketersediaan_ruang_penyimpanan["tidak_ada_penyimpanan"]
                .dataPenyusun_desc,
          },

          {
            name: "Jumlah Kendaraan roda tiga",

            value:
              this.alur.ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                "jumlah_kendaraan_roda_tiga"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah Kendaraan roda empat",

            value:
              this.alur.ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                "jumlah_kendaraan_roda_empat"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah Kendaraan perusahaan pengangkut",

            value:
              this.alur.ketersediaan_pengangkutan_limbah_ke_tempat_pengolah[
                "jumlah_kendaraan_perusahaan_pengangkut"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat pengolah limbah insinerator",

            value:
              this.alur.ketersediaan_alat_pengolah_limbah[
                "apakah_terdapat_pengolah_limbah_insinerator"
              ].dataPenyusun_desc,
          },
          {
            name: "Kapasitas Operasinal Insinerator",

            value:
              this.alur.ketersediaan_alat_pengolah_limbah[
                "kapasitas_operasinal_insinerator"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat pengolah limbah autoclave/microwave",

            value:
              this.alur.ketersediaan_alat_pengolah_limbah[
                "apakah_terdapat_pengolah_limbah_autoclave_microwave"
              ].dataPenyusun_desc,
          },
          {
            name: "Kapasitas Operasinal autoclave/microwave",

            value:
              this.alur.ketersediaan_alat_pengolah_limbah[
                "kapasitas_operasinal_autoclave_microwave"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah ingin menggunakan data sebelumnya",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "apakah_ingin_menggunakan_data_sebelumnya_"
              ].dataPenyusun_desc,
          },
          {
            name: "Diolah dengan insinerator",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "diolah_dengan_insinerator"
              ].dataPenyusun_desc,
          },
          {
            name: "No insinerator",

            value:
              this.alur.spesifikasi_pengolahan_limbah["no_insinerator"]
                .dataPenyusun_desc,
          },
          {
            name: "Tangggal Izin insinerator",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "tangggal_izin_insinerator"
              ].dataPenyusun_desc,
          },
          {
            name: "apakah terdapat alat pengolah limbah autoclave/microwave",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "apakah_terdapat_alat_pengolah_limbah_autoclave_microwave"
              ].dataPenyusun_desc,
          },
          {
            name: "No autoclave/microwave",

            value:
              this.alur.spesifikasi_pengolahan_limbah["no_autoclave_microwave"]
                .dataPenyusun_desc,
          },
          {
            name: "Tangggal Izin autoclave/microwave",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "tangggal_izin_autoclave_microwave"
              ].dataPenyusun_desc,
          },
          {
            name: "Bekerja sama dengan pengolah limbah medis",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "bekerja_sama_dengan_pengolah_limbah_medis"
              ].dataPenyusun_desc,
          },
          {
            name: "Nama Perusahaan pengolah limbah sesuai dengan PKS",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "nama_perusahaan_pengolah_limbah_sesuai_dengan_pks"
              ].dataPenyusun_desc,
          },
          {
            name: "No PKS",

            value:
              this.alur.spesifikasi_pengolahan_limbah["no_pks"]
                .dataPenyusun_desc,
          },
          {
            name: "Tanggal PKS",

            value:
              this.alur.spesifikasi_pengolahan_limbah["tanggal_pks"]
                .dataPenyusun_desc,
          },
          {
            name: "Diolah dengan metode lainnya",

            value:
              this.alur.spesifikasi_pengolahan_limbah[
                "diolah_dengan_metode_lainnya"
              ].dataPenyusun_desc,
          },
          {
            name: "Disinfeksi untuk limbah infeksius padat",
            value:
              this.alur.pengolahan_limbah_metode_lainnya[
                "disinfeksi_untuk_limbah_infeksius_padat"
              ].dataPenyusun_desc,
          },
          {
            name: "Inertifikasi / solidifikasi untuk limbah farmasi dan kimia padat",
            value:
              this.alur.pengolahan_limbah_metode_lainnya[
                "inertifikasi___solidifikasi_untuk_limbah_farmasi_dan_kimia_padat"
              ].dataPenyusun_desc,
          },
          {
            name: "Enkapsulasi untuk limbah tajam",
            value:
              this.alur.pengolahan_limbah_metode_lainnya[
                "enkapsulasi_untuk_limbah_tajam"
              ].dataPenyusun_desc,
          },
          {
            name: "Penguburan limbah patologi",
            value:
              this.alur.pengolahan_limbah_metode_lainnya[
                "penguburan_limbah_patologi"
              ].dataPenyusun_desc,
          },
          // form 4
          {
            name: "Rata-rata debit air masuk IPAL",

            value:
              this.limbah_cair.debit["rata_rata_debit_air_masuk_ipal"]
                .dataPenyusun_desc,
          },
          {
            name: "Rata-rata debit air keluar IPAL",

            value:
              this.limbah_cair.debit["rata_rata_debit_air_keluar_ipal"]
                .dataPenyusun_desc,
          },
          {
            name: "Rata-rata pH harian",

            value:
              this.limbah_cair.kualitas_air_limbah["rata_rata_ph_harian"]
                .dataPenyusun_desc,
          },
          {
            name: "Rata-rata suhu harian",

            value:
              this.limbah_cair.kualitas_air_limbah["rata_rata_suhu_harian"]
                .dataPenyusun_desc,
          },
          {
            name: "Ketersediaan Instalasi pengeolaan air limbah (IPAL)",

            value:
              this.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                "ketersediaan_instalasi_pengeolaan_air_limbah__ipal_"
              ].dataPenyusun_desc,
          },
          {
            name: "Jumlah kapasitas IPAL",

            value:
              this.limbah_cair.instalasi_pengelolaan_air_limbah__ipal_[
                "jumlah_kapasitas_ipal"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah terdapat izin pembuangan limbah cair (IPLC)",

            value:
              this.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                "apakah_terdapat_izin_pembuangan_limbah_cair__iplc_"
              ].dataPenyusun_desc,
          },
          {
            name: "No IPLC",

            value:
              this.limbah_cair.izin_pembuangan_limbah_cair__iplc_["no_iplc"]
                .dataPenyusun_desc,
          },
          {
            name: "Tanggal Izin IPLC",

            value:
              this.limbah_cair.izin_pembuangan_limbah_cair__iplc_[
                "tanggal_izin_iplc"
              ].dataPenyusun_desc,
          },
          {
            name: "Apakah baku mutu limbah cair sesuai",

            value:
              this.limbah_cair.baku_mutu_limbah_cair[
                "apakah_baku_mutu_limbah_cair_sesuai"
              ].dataPenyusun_desc,
          },
          {
            name: "No mutu limbah cair",

            value:
              this.limbah_cair.baku_mutu_limbah_cair["no_mutu_limbah_cair"]
                .dataPenyusun_desc,
          },
          {
            name: "Tanggal mutu limbah cair",

            value:
              this.limbah_cair.baku_mutu_limbah_cair["tanggal_mutu_limbah_cair"]
                .dataPenyusun_desc,
          },
        ],
      };
      if (draft) {
        this.temporarySave(body);
      } else {
        this.saveFormulir(body);
      }
    },
    tabLanjut() {
      if (this.tabs == "tabs1") {
        this.tabs = "tabs2";
        this.paginNow++;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs3";
        this.paginNow++;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs4";
        this.paginNow++;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    tabKembali() {
      if (this.tabs == "tabs4") {
        this.tabs = "tabs3";
        this.paginNow--;
      } else if (this.tabs == "tabs3") {
        this.tabs = "tabs2";
        this.paginNow--;
      } else if (this.tabs == "tabs2") {
        this.tabs = "tabs1";
        this.paginNow--;
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
  },
};
</script>

<style scoped>
.side_toolbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 99 !important;
}
.item_toolbar {
  margin: 0 20px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgb(0 180 204 / 18%) 0px 5px 7px !important;
}
.long_input {
  width: 250px;
}
.long_input:placeholder {
  font-size: small;
}
.long_input:focus {
  outline: none;
}
</style>